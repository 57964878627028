import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import React, { useState, useEffect } from "react";

export const ProfilePhoto: React.FC<{ pca: PublicClientApplication }> = ({
  pca,
}) => {
  const [photoURL, setPhotoURL] = useState<string | null>(null);

  useEffect(() => {
    async function fetchPhoto() {
      try {
        const account = pca.getActiveAccount() || pca.getAllAccounts()[0];
        if (account) {
          // Token request logic
          // 1. Obtain an Access Token for Microsoft Graph
          const tokenResponse = await pca.acquireTokenSilent({
            account: pca.getAllAccounts()[0],
            scopes: ["User.Read"],
          });

          // 2. Use the Access Token to fetch profile information
          const userInfoEndpoint =
            "https://graph.microsoft.com/v1.0/me/photo/$value";
          const response = await fetch(userInfoEndpoint, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          });

          if (!response.ok) {
            throw new Error("Error fetching user profile photo");
          }

          // 3. Convert the response to a Blob and generate a URL for image display
          const photoBlob = await response.blob();
          const photoURL = URL.createObjectURL(photoBlob);
          setPhotoURL(photoURL);
        } else {
          // Handle scenario when no account is available
          // handle auth redired/do all initial setup for msal
          pca
            .handleRedirectPromise()
            .then((_) => {
              // Check if user signed in
              const account = pca.getActiveAccount();
              if (!account) {
                // redirect anonymous user to login page
                pca.loginRedirect();
              }
            })
            .catch((err) => {
              // TODO: Handle errors
              console.log(err);
            });

          // window.location.reload();
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // Fallback to interactive method if token acquisition fails
          // You might redirect the user to login or use acquireTokenPopup/acquireTokenRedirect
        }
        console.error(error);
      }
    }

    fetchPhoto();
  }, [pca]);

  if (!photoURL) {
    return <div>Loading...</div>; // or return null or a placeholder image
  }

  return <img className="h-8 w-8 rounded-full" src={photoURL} alt="Profile" />;
};
